import React from 'react'
import Menu from './menu'
import { useSiteFooterMenu } from '../../hooks/use-site-footermenu'

const FooterMenu = () => {
  const { edges } = useSiteFooterMenu()

  return <Menu fd="column" footer menuItems={[...edges[0].node.menu]} />
}

export default FooterMenu
