import React from 'react'
import Menu from './menu'
import { useSiteMainMenu } from '../../hooks/use-site-mainmenu'

const MainMenu = () => {
  const { edges } = useSiteMainMenu()

  return <Menu menuItems={edges[0].node.menu} />
}

export default MainMenu
