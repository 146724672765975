import React from 'react'
/* eslint-disable max-len */
const MobileNavIcon = () => {
  return (
    <svg className="hamburger" width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line className="l" x1="3" y1="4.5" x2="24" y2="4.5" stroke="black"/>
      <line className="l" x1="3" y1="12.5" x2="24" y2="12.5" stroke="black"/>
      <line className="l" x1="3" y1="20.5" x2="24" y2="20.5" stroke="black"/>
      <line className="x" y1="-0.5" x2="23.3452" y2="-0.5" transform="matrix(0.7282 -0.685365 0.524959 0.851128 5 21)" stroke="black"/>
      <line className="x" y1="-0.5" x2="23.3452" y2="-0.5" transform="matrix(0.7282 0.685365 -0.524959 0.851128 5 5)" stroke="black"/>
    </svg>
  )
}

export default MobileNavIcon
