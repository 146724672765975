import React, { useState } from 'react'
import dayjs from 'dayjs'
import { shouldForwardList } from '../utils'
import styled from '@emotion/styled'
import { colors } from '../styles/variables'

const Admin = styled('div', {
  shouldForwardProp: prop => shouldForwardList(prop)
})`
  display: flex;
  position: fixed;
  height: 120px;
  bottom: 0;
  padding: 20px;
  right: 0;
  left: 0;
  background-color: ${ colors.brand };
  z-index: 10;
`

const Trigger = styled('button', {
  shouldForwardProp: prop => shouldForwardList(prop)
})`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${ colors.brandDark };
  z-index: 10;
`

const InlineBtn = styled('button', {
  shouldForwardProp: prop => shouldForwardList(prop)
})`
  text-decoration: underline;
  display: block;
`

const AdminBar = () => {
  const lastBuild = dayjs(parseInt(process.env.GATSBY_BUILD_TIME)).format('dddd DD MMMM YYYY hh:mm:ss A')
  const [showBar, setBar] = useState(true)

  return (
    <>
      {showBar
        ? (
          <Admin>
            <b>Last Build </b> : {lastBuild}
            <InlineBtn onClick={() => setBar(false)}>Hide This Bar</InlineBtn>
            <InlineBtn onClick={() => window.location.reload()}>Refresh Page</InlineBtn>
          </Admin>
        )
        : (
          <Trigger onClick={() => setBar(true)}>Show Admin Bar</Trigger>
        )}
    </>
  )
}

export default AdminBar
