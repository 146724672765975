import { useStaticQuery, graphql } from 'gatsby'

export const useLandingMenu = () => {
  const { allContentfulMenu } = useStaticQuery(
    graphql`
      query {
        allContentfulMenu(filter: { title: { eq: "Landing Page Menu" } }) {
          ...menuFragment
        }
      }
    `
  )
  return allContentfulMenu
}
