import React from 'react'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import reset from '../styles/reset'
import helpers from '../styles/helpers'
import fontFace from '../styles/fontFace'
import Header from './header'
import AdminBar from './adminBar'
import { colors } from '../styles/variables'
import Footer from './footer'

const Wrapper = styled('main')`
  padding-top: 0px;
  background-color: ${ colors.neutral };
`

const Layout = props => {
  const branch = process.env.GATSBY_BRANCH
  const small = props.header
  const isLandingPage = props.landingpage
  const isLandingPageMenu = props.landingpagemenu
  const category = props.category
  return (
    <>
      <Global
        styles={css`
          ${ fontFace }
          ${ reset }
          ${ helpers }
        `}
      />
      <Header small={small} isLandingPage={isLandingPage} isLandingPageMenu={isLandingPageMenu} category={category}/>
      {branch === 'staging' && <AdminBar />}

      <Wrapper className="font-sans">{props.children}</Wrapper>
      {
        props.footerShow === 'hide'
          ? ''
          : <Footer footerShow={props.footerShow} />
      }
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
