import React from 'react'
/* eslint-disable max-len */
const FacebookLogo = () => {
  return (
    <svg
      width="12"
      height="24"
      viewBox="0 0 12 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6079 23.5861V12.8421H11.0873L11.6068 8.63648H7.6079V5.95886C7.6079 4.7422 7.93428 3.91831 9.60756 3.91831H11.7451V0.162937C10.714 0.0518083 9.67835 -0.0023189 8.6422 7.6108e-05C5.56687 7.6108e-05 3.47067 1.9544 3.47067 5.54213V8.63648H0V12.8421H3.47067V23.5861H7.6079Z"
        fill="black"
      />
    </svg>
  )
}

export default FacebookLogo
