import React from 'react'
import styled from '@emotion/styled'
import { breakpoints } from '../styles/variables'
import Logo from './svg/smakkLogo'
import HandIcon from '../images/hand_icon.png'
import useIntersect from './useIntersect'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'

/* eslint-disable max-len */

const EndFooter = () => {
  const buildThresholdArray = () => Array.from(Array(100).keys(), i => i / 100)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray()
  })
  return (
    <div className="relative pb-8" ref={ref} entry={entry}>
      <div className={`transform-gpu transition-transform cursor-pointer absolute w-48 md:w-full md:max-w-xs lg:max-w-md top-full left-1/2 -translate-x-1/2 ${ (entry.intersectionRatio > 0.8 ? '-translate-y-full' : '-translate-y-1/2') }`}>
        <StaticImage onClick={() => {
          window && window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        } } src='../images/hand_icon.png' alt="Hand" aria-hidden="true" />
      </div>
      <Logo />
    </div>
  )
}

export default EndFooter
