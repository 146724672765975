import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'
import { colors, radius, spacing, fonts, base, breakpoints } from '../../styles/variables'

const LinkRoot = styled(GatsbyLink)`
  font-family: ${ fonts.poppins };
  font-size: ${ base.value * 1.5 }px;
  line-height: ${ base.value * 2.75 }px;
  font-weight: 600;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  align-items: center;
  color: currentColor;
  text-decoration: none;
  transition: all 0.2s;
  position: relative;
  display: inline-block;
  /* overflow: hidden; */
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    font-size: ${ base.value * 1.5 }px;
    line-height: ${ base.value * 2.5 }px;
  }
  ::after {
    content: '';
    position: absolute;
    width: calc(100% - 0.4em);
    height: 2px;
    background-color: ${ colors.purple };
    bottom: -8px;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s;
  }
  :hover,
  :focus,
  &.highlight {
    ::after {
      opacity: 1;
    }
  }
  svg {
    fill: ${ colors.brandBright };
    margin-right: ${ spacing['2xs'] }px;
  }
  :hover, &.highlight {
    color: ${ colors.purple };
    span {
      border-bottom: 1px solid ${ colors.eggplant };
    }
    svg {
      fill: ${ colors.purple };
      transition: inherit;
    }
  }
`

const ArrowLinkRoot = styled(GatsbyLink)`
  font-family: ${ fonts.poppins };
  font-size: ${ base.value * 1.5 }px;
  line-height: ${ base.value * 2.75 }px;
  font-weight: 600;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  align-items: center;
  color: currentColor;
  text-decoration: none;
  transition: all 0.2s;
  position: relative;
  display: block;
  /* overflow: hidden; */
  padding-right: 88px;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    font-size: ${ base.value * 1.5 }px;
    line-height: ${ base.value * 2.5 }px;
  }
  span {
    display: block;
    position: relative;
    z-index: 1;
    background-color: ${ props => props.bgcolor };
    color: ${ props => props.color ? props.color : colors.text };
    transition: color 0.2s;
  }
  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transition: transform 0.1s;
    width: 61px;
    height: 16px;
    transform: translate(-20px, -50%);
    fill: ${ props => props.color ? props.color : colors.text };
  }
  :hover {
    svg {
      fill: ${ colors.purple };
      transition: inherit;
      transform: translate(0px, -50%);
    }
    span {
      color: ${ colors.purple };
    }
  }
`
const ExternalArrowLinkRoot = styled('a')`
  font-family: ${ fonts.poppins };
  font-size: ${ base.value * 1.5 }px;
  line-height: ${ base.value * 2.75 }px;
  font-weight: 600;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  align-items: center;
  color: currentColor;
  text-decoration: none;
  transition: all 0.2s;
  position: relative;
  display: block;
  /* overflow: hidden; */
  padding-right: 88px;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    font-size: ${ base.value * 1.5 }px;
    line-height: ${ base.value * 2.5 }px;
  }
  span {
    display: block;
    position: relative;
    z-index: 1;
    background-color: ${ props => props.bgcolor };
    transition: color 0.2s;
  }
  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transition: transform 0.1s;
    width: 61px;
    height: 16px;
    transform: translate(-20px, -50%);
    fill: ${ props => props.color ? props.color : colors.text };
  }
  :hover {
    svg {
      fill: ${ colors.purple };
      transition: inherit;
      transform: translate(0px, -50%);
    }
    span {
      color: ${ colors.purple };
    }
  }
`

const Anchor = styled('a')`
  align-items: center;
  border-radius: ${ radius.default }px;
  font-family: ${ fonts.poppins };
  color: ${ colors.purple };
  text-decoration: none;
  transition: 0.5s;
  :focus {
    box-shadow: 0 0 0 2px ${ colors.purple };
    outline: 0;
    transition: box-shadow 0.15s ease-in-out;
  }
  span {
    border-bottom: 1px solid ${ colors.eggplant };
    display: block;
    transition: inherit;
  }
  svg {
    fill: ${ colors.eggplant };
    margin-right: ${ spacing['2xs'] }px;
  }
  :hover {
    span {
      border-bottom: 1px solid ${ colors.purple };
    }
    svg {
      fill: ${ colors.brand };
      transition: inherit;
    }
  }
`

const Link = props => {
  const { children } = props

  return <LinkRoot {...props}>{children}</LinkRoot>
}

const ArrowLink = props => {
  const { children } = props

  return <ArrowLinkRoot {...props}>{children}</ArrowLinkRoot>
}

const ExternalLink = props => {
  const { children } = props

  return <Anchor {...props}>{children}</Anchor>
}

const ExternalArrow = props => {
  const { children } = props

  return <ExternalArrowLinkRoot {...props}>{children}</ExternalArrowLinkRoot>
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  inline: PropTypes.bool
}

ArrowLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  bgcolor: PropTypes.string,
  inline: PropTypes.bool
}

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  inline: PropTypes.bool
}

ExternalArrow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  inline: PropTypes.bool
}

export { Link, ArrowLink, ExternalLink, ExternalArrow }
