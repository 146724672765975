import React from 'react'
import Menu from './menu'
import { useLandingMenu } from '../../hooks/use-site-landingmenu'

const LandingMenu = () => {
  const { edges } = useLandingMenu()

  return <Menu menuItems={edges[0].node.menu} />
}

export default LandingMenu
