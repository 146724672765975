import styled from '@emotion/styled'
import { colors, spacing, breakpoints, grid } from '../../styles/variables'
import { H5 } from './typography'

export const OuterSection = styled('div')`
  background-color: ${ props => ((props.bgcolor && props.bgImage) ? 'transparent' : props.bgcolor) };
  &:first-of-type {
    margin-top: 120px;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      margin-top: 73px;
    }
  }
`

export const Section = styled('div')`
  width: 100%;
  max-width: ${ grid.width }px;
  min-height: ${ props => (props.fullpage ? 'calc(100vh - 60px)' : '0') };
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${ props => (props.color ? props.color : colors.text) };
  background-color: ${ props => ((props.bgcolor && props.bgImage) ? 'transparent' : props.bgcolor) };
  margin: 0;
  padding: ${ spacing.xl }px ${ spacing.xl * 1.5 }px;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    padding: ${ spacing.lg }px ${ spacing.lg * 2 }px;
    min-height: 0;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: ${ spacing.lg }px ${ spacing.md }px;
    min-height: 0;
  }
  &.fullwidth {
    max-width: unset;
  }
  &.typehero {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .typehero__headline {
      flex: 1 1 100%;
      height: 100%;
      position: relative;
    }
    h1 {
      position: absolute;
      display: block;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      max-width: ${ grid.width * 0.75 }px;

      min-height: 3.5em;
      line-height: 1;
      font-size: 80px;
      width: calc(100vw - 20rem);
    }
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      height: calc(100vh - 4em);
      padding-bottom: 0px;
      h1 {
        min-height: 5em;
        line-height: 0.8;
        font-size: 48px;
        width: calc(100vw - 4rem);
      } 
    }
  }
  &.callout {
    padding-top: ${ spacing.xl * 2 }px;
    padding-bottom: ${ spacing.xl * 2 }px;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      padding-top: ${ spacing.lg * 1 }px;
      padding-bottom: ${ spacing.lg * 1 }px;
    }
  }
  &.focusAreas {
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    display: block;
    min-height: unset;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      padding: 0;
    }
  }
  > div {
    transition: opacity 0.5s, transform 0.8s;
  }
  &.unshown {
    > div {
      opacity: 0;
      transform: translateY(8vh);
    }
  }
  &.shown {
    > div {
      opacity: 1;
      transform: translateY(0vh);
    }
  }
`

export const FlexBox = styled('div')`
  width: 100%;
  padding-right: ${ spacing.md }px;
  height: 100%;
  display: flex;
  flex-direction: ${ props => props.direction };
`

export const FlexItem = styled('div')`
  flex-basis: ${ props => props.basis }%;
`

export const SectionTitle = styled(H5)`
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  height: 100%;
  width: 8em;
  max-width: none;
  pointer-events: none;
  opacity: ${ props => (props.vision * 100 > 75 ? 1 : 0) };
  transition: opacity 0.3s;
  text-align: center;
  margin: 0;
  .section_title__inner {
    position: relative;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  span {
    transform: translate(calc(-50% + 24px), 0) rotate(-90deg);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100vh;
    color: ${ props => props.color };
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    display: none;
  }
`

export const GridContainer = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, minmax(0px, 1fr));
  grid-row-gap: ${ spacing.sm }px;
  grid-column-gap: ${ spacing.sm }px;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    grid-row-gap: ${ spacing.sm }px;
  }
`

export const GridItem = styled('div')`
  grid-column: span ${ props => (props.span ? props.span : '1') };
  display: ${ props => (props.flex ? 'flex' : 'block') };
  flex-direction: ${ props => (props.direction !== '' ? `${ props.direction }` : 'column') };
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    grid-column: span ${ props => (props.m ? props.m : '12') };
  }
`

export const WorkSection = styled(Section)`
  padding-right: 0;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    padding-right: 0;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding-right: ${ spacing.md }px;
  }
`

export const WorkGrid = styled(GridContainer)`
  grid-auto-flow: dense;
  grid-template-columns: repeat(12, minmax(0px, 1fr));
  grid-auto-rows: minmax(0px, 1fr);
  grid-gap: 1rem;
`

export const WorkItem = styled(GridItem)`
  position: relative;
  overflow: hidden;
  grid-column: span 4;
  .textContent {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      color: white;
      text-align: center;
      opacity: 0;
  }
  &:hover {
    .textContent {
      opacity: 1;
    }
  }
  /* grid-row: span 1; */
  &:nth-of-type(3) {
    grid-column: 5 / span 8;
    grid-row: 1 / span 2;
  }
  &:nth-of-type(6) {
    grid-column: 1 / span 8;
    grid-row: 3 / span 2;
  }
  &:nth-of-type(9) {
    grid-column: 5 / span 8;
    grid-row: 5 / span 2;
  }
  &:nth-of-type(12) {
    grid-column: 1 / span 8;
    grid-row: 7 / span 2;
  }
  &:nth-of-type(15) {
    grid-column: 5 / span 8;
    grid-row: 9 / span 2;
  }
  &:nth-of-type(18) {
    grid-column: 1 / span 8;
    grid-row: 11 / span 2;
  }
  &:nth-of-type(21) {
    grid-column: 5 / span 8;
    grid-row: 13 / span 2;
  }
  &:nth-of-type(24) {
    grid-column: 1 / span 8;
    grid-row: 15 / span 2;
  }
  &:nth-of-type(27) {
    grid-column: 5 / span 8;
    grid-row: 17 / span 2;
  }
  &:nth-of-type(30) {
    grid-column: 1 / span 8;
    grid-row: 19 / span 2;
  }
  &:nth-of-type(33) {
    grid-column: 5 / span 8;
    grid-row: 21 / span 2;
  }
  &:nth-of-type(36) {
    grid-column: 1 / span 8;
    grid-row: 23 / span 2;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    grid-column: auto / span 12 !important;
    grid-row: auto / span 1 !important;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  .video_wrapper {
    height: 102%;
    overflow: hidden;
    position: relative;
    video {
      height: 102%;
      position: absolute;
      max-width: unset;
      width: 100%;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }
`
