import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import MainMenu from './menus/mainMenu'
import { useScrollYPosition } from 'react-use-scroll-position'
import { useScrollDirection } from '../hooks/use-scrollDirection'
import Logo from './svg/smakkLogo'
import { colors, spacing, breakpoints, fonts, base, grid } from '../styles/variables'
import { Link as GatsbyLink } from 'gatsby'
import { ArrowLink, Link } from './shared/link'
import ArrowRight from './svg/arrowRight'
import MobileNavIcon from './svg/mobileNavIcon'
import LandingMenu from './menus/landingMenu'

const MobileNavTrigger = styled('a')`
  display: none;
  flex: 0 0 40px;
  margin: 0 16px;
  .hamburger {
    height: 100%;
    .l {
      stroke-dasharray: 24;
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.2s;
    }
    .x {
      stroke-dasharray: 24;
      stroke-dashoffset: 24;
      transition: stroke-dashoffset 0.2s;
    }
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    display: block;
  }
  @media screen and (max-width: ${ breakpoints.mobile }px) {
    margin: 0 8px;
  }
  &.open {
    .hamburger {
      height: 100%;
      border-block-end-width: 100%;
      .l {
        stroke-dashoffset: 24;
      }
      .x {
        stroke-dashoffset: 0;
      }
    }
  }
`

const HeaderCon = styled('header')`
  position: fixed;
  will-change: height, padding;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  background: ${ colors.neutral };
  color: black;
  border-bottom: 1px solid ${ colors.text };
  border-color: ${ props => (props.theme === 'dark' ? 'transparent' : 'transparent') };
  transition: all 0.3s;
  transform: translateY(${ props => (props.showHeader ? '0px' : '-120px') });
//   padding: ${ spacing.md }px 140px ${ spacing.md }px ${ spacing.xl * 2 }px;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    &.scrolled.down {
      transform: translateY(-100px);
      opacity: 0;
    }
    &.open.scrolled.down {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .smakk_logo {
    max-width: 80%;
    height: 40px;
    justify-self: center;
    rect {
      fill: black;
    }
  }
  .mobile_menu {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 73px;
    height: calc(100vh - 73px);
    width: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    background-color: ${ colors.neutral };
    overflow-x: hidden;
    overflow-y: auto;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      display: flex;
      flex-direction: column;
      a,
      .our-expertise {
        display: block;
        width: 100%;
        padding: 16px 44px;
        border-bottom: 1px solid ${ colors.text };
        &:hover,
        &:focus,
        &.highlight {
          &::after {
            display: none;
          }
        }
      }
      .start-proj-mobile {
        background: ${ colors.purple };
        &:hover {
          span {
            color: ${ colors.text };
            border-bottom: none;
          }
          svg {
            fill: ${ colors.text };
            transform: translate(-20px, -50%);
          }
        }
      }
    }
    .our-expertise {
      font-family: ${ fonts.poppins };
      font-size: ${ base.value * 1.5 }px;
      line-height: ${ base.value * 2.75 }px;
      font-weight: 600;
      letter-spacing: 0.4em;
      text-transform: uppercase;
      ul {
        height: 0;
        overflow: hidden;
      }
      ul li {
        opacity: 0;
        transition: opacity 0.2s;
        &:nth-of-type(1) {
          transition-delay: 0.2s;
        }
        &:nth-of-type(2) {
          transition-delay: 0.3s;
        }
        &:nth-of-type(3) {
          transition-delay: 0.4s;
        }
        &:nth-of-type(4) {
          transition-delay: 0.5s;
        }
        &:nth-of-type(5) {
          transition-delay: 0.6s;
        }
      }
      &:hover {
        color: black;
      }
      span {
        display: block;
        border-bottom: none;
      }
      ul li a {
        padding: 8px 0;
        border-bottom: 0;
        text-transform: capitalize;
        letter-spacing: 0.1em;
        font-weight: 500;
      }
      .our-expertise__trigger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .plus {
          width: 24px;
          height: 24px;
          transition: transform 0.2s;
          transform-origin: center;
        }
      }
      &.open {
        ul {
          height: auto;
        }
        ul li {
          opacity: 1;
        }
        .our-expertise__trigger {
          margin-bottom: 12px;
          .plus {
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  &.open {
    .mobile_menu {
      opacity: 1;
      pointer-events: all;
    }
  }

  /* a {
    color: black;
    height: 100%;
    width: 100%;
  } */
  &.scrolled {
    background-color: ${ colors.neutral };
    height: 60px;
    border-bottom: 1px solid ${ colors.text };
    @media screen and (max-width: ${ breakpoints.desktop }px) {
      // padding: ${ spacing.sm }px ${ spacing.md }px ${ spacing.sm }px ${ spacing.lg * 2 }px;
    }
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      // padding: ${ spacing.sm }px;
      height: auto;
    }
  }
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    // padding-left: ${ spacing.lg * 2 }px;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    /* position: relative; */
    height: auto;
    flex-wrap: wrap;
    border-bottom: 1px solid ${ colors.text };
    // padding: ${ spacing.sm }px;
  }
`

const InnerHeader = styled('div')`
  height: 100%;
  /* max-width: ${ grid.width }px; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-items: end;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    height: auto;
    flex-wrap: wrap;
  }
`

const Header = props => {
  const pageY = useScrollYPosition()
  const scrollDirection = useScrollDirection()
  let position = props.position
  const small = props.small
  let scrolledHeader = false
  const isLandingPageMenu = props.isLandingPageMenu

  if (pageY > 50) {
    position = 'dark'
    scrolledHeader = true
  }

  const [open, openMenu] = useState(false)

  const [down, setDown] = useState(false)

  useEffect(
    prevPageY => {
      if (pageY > prevPageY) {
        setDown(true)
      }
    },
    [down, pageY, setDown]
  )

  return (
    <HeaderCon
      className={
        (scrolledHeader || small ? 'scrolled py-4 md:py-2 ' : 'py-4 md:py-8 ') +
        (open ? 'open ' : ' ') +
        (scrollDirection === 'down' ? 'down ' : ' ') +
        'px-8 md:px-24 lg:px-32 transform-gpu'
      }
      id="header"
      theme={position}
      showHeader="true"
      scrolled={scrolledHeader}
      scrollAmount={position}
      position={pageY}
      down={scrollDirection}
    >
      <InnerHeader>
        {isLandingPageMenu
          ? ('')
          : (<MobileNavTrigger onClick={() => openMenu(prevOpen => !prevOpen)} className={open ? 'open' : ''}>
            <MobileNavIcon />
          </MobileNavTrigger>)
        }
        {isLandingPageMenu
          ? (<span className="flex-shrink-0"><Logo theme={position} /></span>)
          : (<GatsbyLink to="/" className="flex-shrink-0"><Logo theme={position} /></GatsbyLink>)
        }
        {isLandingPageMenu
          ? (<LandingMenu theme={position} />)
          : (<MainMenu theme={position} />)
        }
        {isLandingPageMenu
          ? ('')
          : (<div className="mobile_menu">
            <Link to="/about" activeClassName="highlight">
            About
            </Link>
            <Link to="/work" activeClassName="highlight">
            Work
            </Link>
            <Link to="/mission-plan">Mission Plan</Link>
            <Link to="/careers">Careers</Link>
            <ArrowLink className="start-proj-mobile" to="/start-a-project">
              <span>Start a Project</span>
              <ArrowRight />
            </ArrowLink>
          </div>)
        }
      </InnerHeader>
    </HeaderCon>
  )
}

export default Header
