import { css } from '@emotion/react'
import { spacing, breakpoints } from '../styles/variables'

const helpers = css`
body {
    background-color: #FFFBF6;
}

.m-unset > * {
    margin: 0 !important;
}

.stack > * + * {
    margin-top: ${ spacing.sm }px !important;
}

.stack--large > * + * {
    margin-top: ${ spacing.md }px !important;
}

.jc {
    justify-content: center !important;
}

.je {
    justify-content: end !important;
}

.js {
    justify-content: start !important;
}

.jb {
    justify-content: space-between !important;
}

.ac {
    align-items: center !important;
}

.as {
    align-items: start !important;
}

.ab {
    align-items: space-between !important;
}

.h100 {
    height: 100% !important;
}

.hide-mobile {
    @media screen and (max-width: ${ breakpoints.tablet }px) {
        display: none !important;
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateY(10vh);
    transition: transform 250ms ease-in-out, opacity 200ms ease-in-out;
    &--before-close {
        opacity: 0;
    }
    &--after-open {
        z-index: 9999;
        opacity: 1;
        background-color: rgba(255,255,255,0.1) !important;
        transform: translateY(0vh);
    }
}

.ReactModal__Content {
    border: none !important;
    padding: 0 !important;
    border-radius: 0px !important;
    @media (max-width: 50rem) {
        top: 20px !important;
        bottom: 20px !important;
        left: 20px !important;
        right: 20px !important;
    }
}

body.ReactModal__Body--open {
    height: 100vh;
    overflow: hidden;
}

`

export default helpers
