import React from 'react'
import FooterMenu from './menus/footerMenu'
import styled from '@emotion/styled'
import { colors, breakpoints } from '../styles/variables'
import InstagramLogo from './svg/social--instagram'
import LinkedInLogo from './svg/social--linkedin'
import FacebookLogo from './svg/social--fb'
import { H4, Paragraph } from '../components/shared/typography'
import ArrowRight from './svg/arrowRight'
import { ArrowLink, ExternalArrow, ExternalLink } from '../components/shared/link'
import { Section, GridContainer, GridItem } from '../components/shared/section'
import EndFooter from '../components/endFooter'
import { StaticImage } from 'gatsby-plugin-image'
import { Link as GatsbyLink } from 'gatsby'

/* eslint-disable max-len */

export const FooterSection = styled(Section)`
  display: block;
  max-width: unset;
  overflow: hidden;
  svg rect {
    fill: black;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding-bottom: 0;
  }
  .link-style {
    text-decoration: none;
    color: ${ colors.text };
  }
  .footer__copyright {
    padding: 2em 0;
  }
`

export const FooterGrid = styled(GridContainer)`
  padding: 1px 0px;
  grid-gap: 1px !important;
  position: relative;
  background: ${ colors.text };
  margin-bottom: 0;
  > div {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: ${ props => (props.direction !== '' ? `${ props.direction }` : 'column') };
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2.5em 0;
    /* border-right: 2px solid black;
    border-bottom: 2px solid black; */
    background: ${ colors.neutral };
  }
  .footer__inquire {
    grid-column: 1 / span 8;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      grid-column: 1 / span 12;
      grid-row: 1 / span 1;
    }
  }
  .footer__social {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .footer__menu_list {
    grid-row: 1 / span 3;
    grid-column: 9 / span 4;
    padding: 2.5em;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      grid-column: 1 / span 12;
      grid-row: 2 / span 1;
      padding: 2.5em 0;
      a {
        display: block;
      }
    }
  }
  .footer__about {
    grid-row: 2 / span 1;
    grid-column: 1 / span 8;
    @media screen and (max-width: ${ breakpoints.tablet }px) {
      grid-column: 1 / span 12;
      grid-row: 3 / span 1;
    }
  }
  .grid_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${ colors.text };
    z-index: 0;
  }
  .smakk_logo {
    width: 100%;
  }
  a {
    max-width: 100%;
    word-break: break-word;
  }
`

const SocialLink = styled(ExternalLink)`
  padding: 0;
  margin: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  svg {
    margin-right: 1em;
  }
  &:hover {
      svg {
         fill: ${ colors.purple };
      }
      path {
         fill: ${ colors.purple };
      }
  }
`

const currentYear = new Date().getFullYear()

const Footer = () => {
  return (
    <FooterSection>
      <FooterGrid>

        <GridItem span="4" direction="column" flex className="footer__inquire m-unset stack">
          <Paragraph>New Business Inquiries</Paragraph>
          <ExternalArrow style={{ marginBottom: '16px', paddingRight: '0' }} href="mailto:hello@smkkstudios.com">
            <span>hello@smkkstudios.com</span>
          </ExternalArrow>
          <ArrowLink style={{ marginBottom: '16px' }} to="/start-a-project">
            <span>Start a Project</span>
            <ArrowRight />
          </ArrowLink>
        </GridItem>

        <GridItem span="3" direction="column" className="footer__menu_list m-unset stack--large">
          <div className="footer__social">
            <SocialLink target="_blank" rel="noopener" href="https://facebook.com/smkkstudios">
              <FacebookLogo />
            </SocialLink>
            <SocialLink target="_blank" rel="noopener" href="https://instagram.com/smakk_studios">
              <InstagramLogo />
            </SocialLink>
            <SocialLink target="_blank" rel="noopener" href="https://www.linkedin.com/company/sm&kk-studios/">
              <LinkedInLogo />
            </SocialLink>
          </div>
          <Paragraph><a className="link-style" href="tel:917-617-2539">917.617.2539</a></Paragraph>
          <FooterMenu />
          <Paragraph><GatsbyLink to="/privacy-policy" className="text-xs tracking-wide">Privacy Policy</GatsbyLink></Paragraph>
        </GridItem>

        <GridItem span="8" direction="column" className="footer__about m-unset stack">
          <H4>MWBE certified. est.2011.</H4>
          <Paragraph>Proudly one of the .1% of creative agencies owned by women.</Paragraph>
        </GridItem>

        <div className="!grid grid-cols-3 md:grid-cols-[minmax(0,1fr),minmax(0,1fr),minmax(0,1fr)] col-span-full md:col-span-8 !p-0 !bg-transparent gap-px">
          <div className="lg:px-4 bg-neutral flex items-center justify-center h-full px-2 py-8">
            <StaticImage objectFit="contain" className="w-full h-full max-w-[240px] max-h-[64px]" src="../images/seal-ffc.png" alt="Female Owned Seal" />
          </div>

          <div className="lg:px-4 bg-neutral flex items-center justify-center h-full px-2 py-8">
            <StaticImage objectFit="contain" className="w-full h-full max-w-[240px] max-h-[64px]" src="../images/seal-nyc.png" alt="Made in NYC Seal" />
          </div>

          <div className="lg:px-4 bg-neutral flex items-center justify-center h-full px-2 py-8">
            <StaticImage objectFit="contain" className="w-full h-full max-w-[240px] max-h-[64px]" src="../images/seal-shopify.png" alt="Shopify Experts Seal" />
          </div>

        </div>

      </FooterGrid>

      <div className="footer__copyright">
        <Paragraph>© {new Date().getFullYear()} SMAKK STUDIOS. All rights reserved.</Paragraph>
      </div>

      <EndFooter />

    </FooterSection>
  )
}

export default Footer
