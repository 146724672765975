import React from 'react'
import styled from '@emotion/styled'
import { Link as MyLink, ArrowLink, ExternalLink, ExternalArrow } from '../shared/link'
import ArrowRight from '../svg/arrowRight'
import { breakpoints, colors, variables, base, fonts } from '../../styles/variables'

const Nav = styled('nav')`
  flex: 1 1;
  position: relative;
  z-index: 999;
  &.dont-grow {
    flex-grow: 0;
  }
`

const NavList = styled('ul')`
  display: flex;
  flex-direction: ${ props => (props.fd === 'column' ? `${ props.fd }` : 'row') };
  justify-content: flex-end;
  align-items: ${ props => (props.fd === 'column' ? 'flex-start' : 'center') };
  width: 100%;
  a {
    width: auto;
    letter-spacing: 0.15em;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    a {
      display: none;
    }
    .start_a_project, .start_landing {
      display: block;
      margin-left: 0;
      padding-right: 32px;
      span {
        letter-spacing: 0.15em;
      }
      svg {
        width: 30px;
        transform: translate(0, -50%);
      }
    }
    .start_landing {
        padding: 0.5rem;
        padding-right: 2.5rem;
        svg {
            width: 30px;
            transform: translate(-8px, -50%);
          }
    }
  }
  @media screen and (max-width: ${ breakpoints.phablet }px) {
    .start_a_project {
      width: 120px;
    }
    .start_landing {
      width: auto;
      padding: 0.5rem;
      padding-right: 2.5rem;
    }
  }
`

const Menu = ({ menuItems, fd, footer }) => {
  return (
    <Nav className={fd ? 'dont-grow' : ''}>
      <NavList fd={fd} className={`
      ${ footer ? 'space-y-4' : 'space-x-4 lg:space-x-8' } 
      ${ fd ? 'h100 js' : '' }`}>
        {menuItems.map((item, index) =>
          item.link.includes('mailto')
            ? (
              <ExternalArrow
                target="_blank" rel="noopener noreferrer"
                key={index} href={item.link} style={{ paddingRight: '0' }}>
                {item.linkText}
              </ExternalArrow>
            )
            : item.link.includes('tel:')
              ? (
                <ExternalArrow target="_blank"
                  rel="noopener noreferrer"
                  key={index} href={item.link} style={{ paddingRight: '0' }}>
                  {item.linkText}
                </ExternalArrow>
              )
              : item.link.includes('.')
                ? (
                  <ExternalLink target="_blank" rel="noopener noreferrer" key={index} href={item.link}>
                    {item.linkText}
                  </ExternalLink>
                )
                : item.link.includes('#start')
                  ? (
                    <ArrowLink key={index} to={'/start'} state={{ modal: true }}
                      className="start_landing bg-purple group hover:text-white p-3">
                      <span><div className="group-hover:text-white leading-4">{item.linkText}</div></span>
                      <ArrowRight color='currentColor' />
                    </ArrowLink>
                  )
                  : item.link.includes('start')
                    ? (
                      <ArrowLink key={index} to={item.link} className="start_a_project">
                        <span><div>{item.linkText}</div></span>
                        <ArrowRight />
                      </ArrowLink>
                    )
                    : (
                      <MyLink key={index} to={item.link} activeClassName="highlight">
                        {item.linkText}
                      </MyLink>
                    )
        )}
      </NavList>
    </Nav>
  )
}

export default Menu
