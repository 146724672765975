import React from 'react'

/* eslint-disable max-len */
const LinkedInLogo = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.815476 7.25679H4.97085V21.7388H0.815476V7.25679ZM2.78784 5.44572H2.75776C1.25355 5.44572 0.27832 4.33865 0.27832 2.93672C0.27832 1.50613 1.28238 0.420898 2.81667 0.420898C4.34971 0.420898 5.29235 1.5034 5.32244 2.93263C5.32244 4.33455 4.34971 5.44572 2.78784 5.44572ZM21.5992 21.7406H16.8873V14.245C16.8873 12.2834 16.1502 10.9456 14.5294 10.9456C13.2897 10.9456 12.6003 11.848 12.2794 12.7202C12.1591 13.0315 12.1779 13.4669 12.1779 13.9038V21.7406H7.50979C7.50979 21.7406 7.56996 8.46395 7.50979 7.25723H12.1779V9.53007C12.4536 8.5363 13.9453 7.11799 16.3257 7.11799C19.279 7.11799 21.5992 9.20245 21.5992 13.6867V21.7406Z" fill="black"/>
    </svg>
  )
}

export default LinkedInLogo
