import React from 'react'
import { css } from '@emotion/react'
import { colors } from '../../styles/variables'
/* eslint-disable max-len */
export const colorPulse = css`
  @keyframes colorpulse {
    0% {
      fill: ${ colors.purple };
    }
    20% {
      fill: ${ colors.cobalt };
    }
    60% {
      fill: ${ colors.green };
    }
    80% {
      fill: ${ colors.orange };
    }
    100% {
      fill: ${ colors.purple };
    }
  }
  :hover path {
    animation: colorpulse infinite 6s;
  }
`

/* eslint-disable max-len */
const Logo = () => {
  return (
    <svg css={colorPulse} className="smakk_logo" viewBox="0 0 265 103" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.5127 49.6891L23.3624 37.1715C21.02 35.4559 20.3419 33.9309 20.3419 30.8174V21.5404C20.3419 19.1258 21.2666 17.7915 23.609 17.7915H25.0884C27.4308 17.7915 28.3554 19.1258 28.3554 21.5404V38.76H46.8481V19.6977C46.8481 7.4343 41.3619 0 29.465 0H19.1091C7.21214 0 0 7.4343 0 19.6977V35.3288C0 42.3819 2.46569 46.4485 8.3217 50.8328L24.9035 63.3504C27.2459 65.066 28.3554 66.591 28.3554 69.7045V81.5231C28.3554 83.9377 27.5541 85.145 25.2117 85.145H23.4241C21.0816 85.145 20.2803 83.8741 20.2803 81.5231V60.9994H0V83.3023C0 95.5657 7.21214 102.936 19.1091 102.936H29.7732C41.6702 102.936 46.8481 95.5657 46.8481 83.3023V65.1295C46.8481 57.8859 45.4303 54.0734 39.5127 49.6891Z"
        fill="black"
      />
      <path
        d="M245.212 1.90625L234.856 49.4349V1.90625H216.919H215.439H197.131L184.926 49.4349V1.90625H166.434V97.599L155.276 1.90625H129.202L117.12 95.8199V1.90625H89.6273L83.8946 61.4442L78.3468 1.90625H49.9297V102.936H67.1895V36.3455L73.2921 103H91.2916L98.0106 37.0444V102.936H116.627H117.366H133.948L135.304 86.4158H147.324L148.804 102.936H167.297H167.79H184.926V55.0265L196.145 102.936H215.439H216.364H234.856V55.0265L244.226 102.936H264.445L252.424 51.5953L264.999 1.90625H245.212ZM137.092 70.5305L141.345 27.4497L145.722 70.5305H137.092ZM203.419 51.5318L214.514 8.76868V97.7896L203.419 51.5318Z"
        fill="black"
      />
    </svg>
  )
}

export default Logo
